import React, { useState, useEffect } from 'react'
import Nav1 from '../../components/Nav1/index';
import './index.scss'
import Footer from '../../components/Footer/index';


export default function BackgroundPage() {
    const videoData1 = [
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2940.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2945+2.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2945.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1854.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1855.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1856.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1857.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1858.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1859.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1860.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1862.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1863.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1864.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1865.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1867.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1871.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1872.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1874.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2932.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2936.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2937.mp4',
      
      
        
    
    ];
    const videoData2 =[
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2939.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2941.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2943.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2944.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2947.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2948.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1851.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1853.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1861.mov',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1868.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1873.mov',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1875.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1876.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1877.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1878.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1879.mov',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_1880.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2933.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2934.mp4',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2935.mov',
        'https://xingtianqrcode.s3.amazonaws.com/IMG_2938.mov',
        
     
    ]
    const [index,setIndex] = useState(0);

    const userId = window.localStorage.getItem('userId');
    const [isCrl,setIsCrl] = useState('nodownload');
    const [videoData,setVideoData] = useState(videoData1);

    const [isShu,setIsShu] = useState(false)
  
    const [style,setStyle] = useState()
  
    
 
    

    useEffect(()=>{
            if(userId){
                setIsCrl('');
            }
    },[userId])

    const handleHeng = ()=>{
        setIndex(0)

        setVideoData(videoData1)

        setIsShu(false)
    }

    const handleShu = ()=>{
      
        setVideoData(videoData2)
        // setWidth('50%')
        setIsShu(true)
        setIndex(0)
        // father.current.style.display
        // console.log(window.screen)

    }   
    // useEffect(()=>{
        

    //     if(isShu){
    //         if(currentWidth<425){
    //             setStyle({display:'grid',gridTemplateColumns:'repeat(2,1fr)' })
    //         }
    //         else{
    //             setStyle({display:'grid',gridTemplateColumns:'repeat(4,1fr)' })
    //         }
    //     }
    //     else{
    //         if(currentWidth<425){
    //             setStyle({display:'grid',gridTemplateColumns:'repeat(1,1fr)' })
    //         }
    //         else{
    //             setStyle({display:'grid',gridTemplateColumns:'repeat(2,1fr)' })
    //         }
    //     }
    // },[currentWidth,isShu])

    const handleLast =  ()=>{
        if(index === 0){
            setIndex(videoData.length)
        }
        else{

            setIndex(index-1)
        }
        // let current = videoData

        //         const temp = current.splice(current.length-1,1)[0];
        //         current.unshift(temp)
        //         setVideoData(current)
        //     console.log(videoData);

    }

    const handleNext = ()=>{
        // let current = videoData
        // const temp = current.splice(0,1)[0];
        // current.push(temp);

        //         setVideoData(current)
        if(index ===videoData.length){
            setIndex(0)

        }
        else{

            setIndex(index+1)
        }


    }

    return (
        <div  className='background-container' >

            <Nav1 Id={userId}/>

            <div className='title-container'>

                    <h2>
                        微信8.0版本以后新增状态栏 各位用户可将以下视频下载为自己的微信背景状态
                    </h2>
                    <h3>所有视频用户登录后方可下载</h3>
            </div>
            <div className='btn-background'>
                <div>
                <button onClick={() =>handleHeng()}>
                        横版视频
                    </button>
                </div>
                   <div>
                   <button onClick={() =>handleShu()}>
                        竖版视频
                    </button>
                   </div>
                   
            </div>

            <div className='video-container' style={style }  >


              { 
                videoData&&<div className='video-item' >
                    <button onClick={handleLast}><img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1612975789/left-chevron-4_r4xh3s.png" alt=""/></button>
                        <video autoPlay={true}  src={videoData[index]} controls controlsList={`${isCrl} nofullscreen`} ></video>
                        <button onClick={handleNext}><img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1612975789/right-chevron-4_nqwg8g.png" alt=""/></button>
                    </div>
               
              }

            </div>
            
           
            <Footer/>
        </div>
    )
}
