import React from 'react'

import Candle from '../../components/Candle/index'

export default function CssPage() {
    return (
        <div>
            <Candle/>
        </div>
    )
}
