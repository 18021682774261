import './App.scss';
import {BrowserRouter as Router, Switch,Route,Redirect} from 'react-router-dom'
// import Pages from './constant/page';
// import shortid from 'shortid';
import PageNotFound from './pages/404/index';
import {React,useState} from 'react';
import LoginPage from './pages/Login/index';
import HomePage from './pages/Home/index';
import PublishPage from './pages/Publish/index';
import UserPage from './pages/User/index';
import ContactPage from './pages/Contact/index';
import RegisterPage from './pages/Register/index';
import PostPage from './pages/Post/index';
import BackgroundPage from './pages/Background/index';
import DouyinPage from './pages/Douyin/index';
import WechatPage from './pages/Wechat/index';
import CssPage from './pages/CssPage/index';
import Page1380 from './pages/1380/index';
import ToolsPage from './pages/Tools/index';
import AdminPage from './pages/Admin/index';
import MassagePage from './pages/Massage/index';
import EnglishPage from './pages/English/index';
import LivePage from './pages/Live/index'
import IframePage from './pages/IframePage/index'
import GamePage from './pages/Game/index';
import NumberPage from './pages/Number/index';
import AdminPublishPage from './pages/Admin-Publish/index'
import Cards from './pages/Cards/index'

function App() {
 

  const [token,setToken] = useState(window.localStorage.getItem('token')? window.localStorage.getItem('token') : '');
  const [userId,setUserId] = useState(window.localStorage.getItem('userId')? window.localStorage.getItem('userId'): '')

  const [newPost,setNewPost] = useState({});
  // console.log(token)


  // console.log(newPost)
  // useEffect(()=>{
  //   window.localStorage.setItem('token',token);
  // },[token])
  return (
    <Router>
        <Switch>
        <Route path='/login' exact render={(history)=><LoginPage history={history} setToken={setToken} setUserId={setUserId}/>}/>

        <Route path='/group' exact render={()=> <Cards userId={userId}/>} />
        <Route path='/contact' exact render={()=> <ContactPage/>} />
        <Route path='/register' exact render={()=> <RegisterPage/>} />
        <Route path='/publish' exact render={()=> token ? <PublishPage setNewPost={setNewPost}/> : <Redirect to='/login'/>} />
        <Route path='/publish/admin' exact render={()=> userId==='601b4a0edebc8608f510cae3'? <AdminPublishPage  setNewPost={setNewPost}/> : <Redirect to='/login'/>} />
        <Route path='/user' exact render={()=> token ? <UserPage/> : <Redirect to='/login'/>} />
        <Route path='/post' exact  render={()=><PostPage/>}  />
        <Route path='/background' exact render={()=> <BackgroundPage/>}/>
        <Route path='/' exact render={()=><WechatPage userId={userId}/>}/>
        <Route path='/douyin' exact render={()=><DouyinPage userId={userId}/>}/>
        <Route path='/css'  exact  render={()=><CssPage/>}/>
        <Route path='/1380'  exact  render={()=><Page1380/>} />
        <Route path='/tool' exact render={()=><ToolsPage/>}/>
        <Route path='/admin' exact render={()=><AdminPage/>}/>
        <Route path='/massage'  exact render={()=><MassagePage/>}/>
        <Route path='/english' exact render={()=><EnglishPage/>}/>
        <Route path='/live' exact render={()=><LivePage/>}/>
        <Route path='/game' exact render={()=><GamePage/>}/>
        <Route path='/iframe' exact render={()=><IframePage/>}/>
        <Route path='/number' exact render={()=><NumberPage/>}/>

              {/* {
                Pages&&Pages.publicPages.map((v)=>(
                  <Route
                    key={shortid.generate()}
                    exact={v.isExact}
                    render={()=>v.component}
                    path={v.path}
                  />
                ))
              }{
                Pages&&Pages.protectPages.map((v,i)=>(
                  <Route
                  key={shortid.generate()}
                  exact={v.isExact}
                  render={()=> token? v.component : <Redirect to={'/login'}/>}
                  />
                ))
              } */}          
              <Route  component={PageNotFound}/>
        </Switch>
    </Router>
  );
}

export default App;
