import React,{useState,useEffect,useRef} from 'react'
import Footer from '../../components/Footer/index';
import './index.scss';
import Candle from '../../components/Candle/index'

import Nav from '../../components/Nav/index';
import { QRCode } from 'react-qrcode-logo';
import {Typography,Button,Grid,TextField,Select,MenuItem} from '@material-ui/core'
import { PhotoshopPicker } from 'react-color';

export default function ToolsPage() {

    const [url,setUrl] = useState('');
    const [logo,setLogo] = useState('')
    const [show,setShow] = useState(false);
    const [size,setSize]= useState(150);
    const [logoSize,setLogoSize] = useState(size*0.2);
    const [style,setStyle] = useState('squares')
    const [opacity,setOpacity]= useState(1)
    const [color,setColor] = useState('#000000')
    const [isPhone,setIsPhone] = useState(false)
    const [downLoad,setDownLoad] = useState('')
    const canvas =useRef(null)
    const handleUrlChange = (e) => {
        setUrl(e.target.value)
    }
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
		const link = URL.createObjectURL(file);
        setLogo(link)
    }

    const handleClick = ()=>{
       if(!url){
           alert('请输入网址')
       }
       
       else{
           setShow(true)
       }
    }

    const handleChangeSize = (e)=>{
        setSize(e.target.value)
    }

    const handleChangeStyle =(e)=>{
        setStyle(e.target.value)
    }

    const handleChangeLogo=(e)=>{
        setLogoSize(e.target.value)
    }

    const handleLogoOpacity = (e)=>{
        setOpacity(e.target.value)
    }
   const  handleChangeComplete = (color) => {
        setColor( color.hex );
      };

      useEffect(()=>{

        const width = window.innerWidth
        if(width>425){
            setIsPhone(true)
        }
        else{
            setIsPhone(false)
        }
      },[])

const handleDownLoad = ()=>{
    const url = canvas.current.canvas.current.toDataURL("image/png");
    setDownLoad(url)
}   
    return (
        <div className='tools'>

            <Nav/>
                <Typography variant='h2' align='center' >二维码生成器</Typography>
                  
                       
                        <Grid container  className='tool-container'>

                                <Grid item xs={12} >

                                <TextField  variant='outlined' multiple placeholder="请输入网址" onChange={(e)=>handleUrlChange(e)} type="text"  value={url}/>
                                </Grid>
                                <Grid item xs={12}>
                                <Typography variant='h6'>上传logo</Typography>

                                </Grid>
                                <Grid item xs={12} >
                                <TextField  variant='outlined'  placeholder="请上传logo" onChange={(e)=>handleLogoChange(e)} type="file" />
                                </Grid>
                                {logo&&<div className='bg'>
                                <img    src={logo} alt="" />
                                </div>}
                                <Button onClick={()=>handleClick()} variant='contained' color='primary'>一键生成二维码</Button>
                                
                               
                        </Grid>

                  
                        {show&&
                                <div className='qr-code'>
                                    <div>
                                        
                                    <QRCode className='canvas' ref={canvas} size={size} logoWidth={logoSize } logoHeight={logoSize } qrStyle={style}  value={url} logoImage={logo} logoOpacity={opacity} fgColor={color}	/> 
                                    <a href={downLoad} download target='_blank' rel="noreferrer">
                                    <Button variant='contained' color='primary' onClick={()=>handleDownLoad()}>download</Button></a>
                                    </div> </div>}
                                   
                                    { isPhone&&<Grid container>
                                    <Grid item xs={12}>
                                
                                </Grid>

                                 <Grid item sm={3} xs={6}>
                                    <Typography>选择图片尺寸</Typography>
                                <Select
                                value={size}
                                onChange={handleChangeSize}
                                >
                                <MenuItem value={125}>125px</MenuItem>
                                 <MenuItem value={150}>150px</MenuItem>
                                <MenuItem value={250}>250px</MenuItem>
                                <MenuItem value={500}>500px</MenuItem>
                                </Select>
                                </Grid> 

                               <Grid item sm={3} xs={6}>
                                <Typography>选择logo尺寸</Typography>
                                <Select
                                value={logoSize}
                                onChange={handleChangeLogo}
                                >
                                <MenuItem value={30}>30px</MenuItem>
                                <MenuItem value={50}>50px</MenuItem>
                                <MenuItem value={75}>75px</MenuItem>
                                <MenuItem value={100}>100px</MenuItem>
                                <MenuItem value={150}>150px</MenuItem>
                                <MenuItem value={250}>250px</MenuItem>
                                <MenuItem value={500}>500px</MenuItem>
                                </Select>

                                </Grid>

                                <Grid item sm={3} xs={6}>
                                <Typography>选择logo透明度</Typography>
                                <Select
                                value={opacity}
                                onChange={handleLogoOpacity}
                                >
                                    <MenuItem value={0.8}>0.8</MenuItem>
                                <MenuItem value={0.7}>0.7</MenuItem>
                                <MenuItem value={0.6}>0.6</MenuItem>
                                <MenuItem value={0.5}>0.5</MenuItem>
                                <MenuItem value={0.4}>0.4</MenuItem>
                                <MenuItem value={0.3}>0.3</MenuItem>
                                <MenuItem value={0.2}>0.2</MenuItem>
                                <MenuItem value={0.1}>0.1</MenuItem>
                                </Select>

                                </Grid>

                                <Grid item sm={3} xs={6}>
                                <Typography>选择二维码风格</Typography>
                                <Select
                                value={style}
                                onChange={handleChangeStyle}
                                >
                                <MenuItem value={'squares'}>正方形</MenuItem>
                                <MenuItem value={'dots'}>点点</MenuItem>
                              
                                </Select>
                                </Grid>

                                <Grid item xs={12}>
                                <Typography>选择二维码颜色</Typography>
                                <PhotoshopPicker
                                color={ color }
                                onChangeComplete={ handleChangeComplete }
      />
                                </Grid> 
                                </Grid>
                               }
                       

                 


            <Candle/>
            <Footer/>            
        </div>
    )
}
