import React,{useState,useEffect} from 'react'
import Candle from '../../components/Candle/index'

export default function AdminPage() {


    const [password,setPassword] = useState('')

    useEffect(() => {
        const result = window.prompt("请输入管理员密码");
        if(result ==='0708David'){
            console.log('Success');
        }
        else{
          

        }
    },[password])
    return (
        <div>
            <h1>I am admin</h1>
            <a href="https://analytics.google.com/analytics/web/#/p260819125/reports/defaulthome?params=_u..nav%3Ddefault%26_u..comparisons%3D%5B%7B%22name%22:%22All%20Users%22,%22filters%22:%5B%7B%22isCaseSensitive%22:true,%22expression%22:%220%22,%22fieldName%22:%22audience%22%7D%5D%7D,%7B%22name%22:%22Device%20category%20includes%20desktop%22,%22filters%22:%5B%7B%22fieldName%22:%22deviceCategory%22,%22expressionList%22:%5B%22desktop%22%5D,%22isCaseSensitive%22:true%7D%5D%7D,%7B%22name%22:%22Device%20category%20includes%20mobile%22,%22filters%22:%5B%7B%22fieldName%22:%22deviceCategory%22,%22expressionList%22:%5B%22mobile%22%5D,%22isCaseSensitive%22:true%7D%5D%7D,%7B%22name%22:%22Device%20category%20includes%20tablet%22,%22filters%22:%5B%7B%22fieldName%22:%22deviceCategory%22,%22expressionList%22:%5B%22tablet%22%5D,%22isCaseSensitive%22:true%7D%5D%7D%5D">Google Analytics</a>
            <Candle/>
        </div>
    )
}
