import React,{useState,useEffect} from 'react'
import Nav1 from '../../components/Nav1/index';
import axios from 'axios';
import getApiUrl from '../../constant/apiUrl';
import Candle from '../../components/Candle/index'

import Footer from '../../components/Footer/index';
import './index.scss'
export default function UserPage() {

    const userId = window.localStorage.getItem('userId');
    const url = getApiUrl();
    const [posts,setPosts] = useState([]);
    const [isDelete,setIsDelete] = useState(false)
    const [qun,setQun] = useState(userId==='601b4a0edebc8608f510cae3' ?false:true)
    const [isCard,setIsCard] = useState(userId==='601b4a0edebc8608f510cae3' ?true:false)
    const [geren,setGeren] = useState(false)
    const [douyin,setDouyin] = useState(false)
    const [wechatPost,setWechatPost] = useState([])
    const [douyinPost,setDouyinPost] = useState([])
    const [isDeleteDouyin,setIsDeleteDouyin] =useState(false)
    const [isDeleteWeChat,setIsDeleteWeChat] = useState(false)
    const [deleteId,setDeleteId] = useState('')
    const [deleteIndex,setDeleteIndex] = useState('')

    useEffect(() => {
        if(userId==='601b4a0edebc8608f510cae3'){
            const fetchUserData = async ()=>{
                const result = await axios.get(`${url}/cards/all`);
                setPosts(result.data.data)
                }
            fetchUserData();
        }
        else{
            const fetchUserData = async ()=>{
                const result = await axios.get(`${url}/users/posts`,{params:{userId}});
                setPosts(result.data.data)
                }
            fetchUserData();
        }
    },[userId,url])

    const handleDelete = (v,i)=>{
        setDeleteId(v._id);
        setDeleteIndex(i);
     setIsDelete(true)
    }


    const handleSure = async()=>{
       if(deleteId){
           await axios.post(`${url}/users/delete`,{
            index:deleteIndex,
            userId,
            postId:deleteId
        })
        window.location.reload();
       }
       
    }

    const handleDeleteCard = async()=>{
        if(deleteId){
            await axios.post(`${url}/cards/delete`,{
                id:deleteId
            })
            window.location.reload();
         }
        
        
    }

    const handleClose = ()=>{
        setIsDelete(false)
        setDeleteId('');
        setDeleteIndex('');

    }
    const handleGetQun = ()=>{
        return axios.get(`${url}/users/posts`,{params:{userId}})
    }
    const handleGetCard=()=>{
        return axios.get(`${url}/cards/all`)
    }
    const handleGetWeChat = ()=>{
        return axios.get(`${url}/users/wechats`,{params:{userId}})
    }

    const handleGetDouYin = ()=>{
        return axios.get(`${url}/users/douyins`,{params:{userId}})
    }

    const handleQun = async() =>{
        
        const data = await handleGetQun()
        if(data.data.success){
            setPosts(data.data.data);
            setWechatPost([])
            setDouyinPost([])

        }
        setDouyin(false)
        setQun(true)
        setGeren(false)
    }
    const handleCard = async() =>{
        const data = await handleGetCard()
        console.log(data);
        if(data.data.success){
            setPosts(data.data.data);
            setWechatPost([])
            setDouyinPost([])

        }
        setDouyin(false)
        setIsCard(true)
        setGeren(false)
    }

    const handleGe = async() =>{
        const data = await handleGetWeChat()
        if(data.data.success){
            setWechatPost(data.data.data);
            setPosts([]);
            setDouyinPost([])

        }
        setDouyin(false)
        setQun(false)
        setGeren(true)
        setIsCard(false)

    }
    const handleDou = async() =>{
        const data = await handleGetDouYin()
        if(data.data.success){
            setDouyinPost(data.data.data);
            setPosts([]);
            setWechatPost([])
        }
        setDouyin(true)
        setQun(false)
        setGeren(false)
        setIsCard(false)

    }

    const handleDeleteDouYin = () =>{
        setIsDeleteDouyin(true)
    }
    const handleDeleteWeChat = () =>{
        setIsDeleteWeChat(true)
    }
    const handleQuit = () =>{
        setIsDeleteDouyin(false)
        setIsDeleteWeChat(false)

    }

    const handleDouyinSure = async(v,i) =>{
        await axios.post(`${url}/users/delete/douyin`,{
            index:i,
            userId,
            douyinId:v._id
        })
        window.location.reload();
    }
    const handleWechatSure = async(v,i) =>{
       await axios.post(`${url}/users/delete/wechat`,{
            index:i,
            userId,
            wechatId:v._id
        })
        window.location.reload();
    }


    return (
        <div className="container">
            <Nav1 Id={userId}/>
            
           
            <div className="user-title">
            <h2>我的发布</h2>
                <div className='select-title'>
                    {userId==='601b4a0edebc8608f510cae3' ? 
                    <div>
                    <h2 onClick={handleCard}>元名片</h2>
                    <hr style={isCard? {backgroundColor:'#4caf50'}:{}}/>
                </div>
                    
                    :<div>
                        <h2 onClick={handleQun}>微信群</h2>
                        <hr style={qun? {backgroundColor:'#4caf50'}:{}}/>
                    </div>}
                    <div>
                         <h2  onClick={handleGe}>微信</h2>
                        <hr  style={geren? {backgroundColor:'#4caf50'}:{}}/>
                    </div>
                    <div>
                        <h2 onClick={handleDou}>抖音</h2>
                        <hr  style={douyin? 
                            {backgroundColor:'#4caf50'}:{}}/>
                    </div>
                </div>
            </div>

            

            <div className="container">
                {
                    posts.length>0&&<div className="container-user"> 
                        {posts.map((v,i)=>(
                            <div className="user-item" key={i}>
                               
                              

                                <div className='item-2'>
                                    <div className='item-left'>
                                        <h3>{v.title}</h3>
                                        <p>{v.description}</p>
                                        <div className='view'>
                                    <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1612220808/visibility_s2tmmk.png" alt="view"/>
                                    <p>{v.views}</p>
                                </div>
                                <div className='view'>
                                <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1612220808/calendar_1_zeqdyx.png" alt="view"/>
                                    <p>{v.date}</p>
                                </div>
                                <button onClick={()=>handleDelete(v,i)} className='delete'>删除</button>
                                    </div>
                                    <div className='view-qr'>
                                        <img src={userId==='601b4a0edebc8608f510cae3'?v.frontImage:v.ownerQRCode} alt=""/>
                                
                                   
                                        <img src={userId==='601b4a0edebc8608f510cae3'?v.qrCode :v.groupQRCode} alt=""/>
                                  
                                    </div>
                                </div>
                                {isDelete&&<div className="user-delete">
                                    <button onClick={()=>{userId==='601b4a0edebc8608f510cae3'?handleDeleteCard() :handleSure()}} className="shanchu">确定删除</button>
                                    <button onClick={handleClose}>退出</button>
                                </div>}
                            </div>
                        ))}
                    </div>

                    
                }

            </div>

            <div className='douyin-user'>
                {
                    douyinPost.length>0&&douyinPost.map((v,i)=>(
                        <div className='douyin-item' key={i}>
                            <p>{v.description}</p>
                            <p>{v.date}</p>
                            <img src={v.qrCode} alt=""/>
                            <button onClick={handleDeleteDouYin}>删除</button>
                            { isDeleteDouyin&&<div className='de-pop'>
                                <button onClick={()=>handleDouyinSure(v,i)}>
                                确认删除
                                </button>
                                    
                                <button onClick={handleQuit}>
                                退出
                                </button>
                            </div>
                        }
                        </div>
                       
                        
                    ))
                }

            </div>
            <div className='douyin-user'>
                {
                    wechatPost.length>0&&wechatPost.map((v,i)=>(
                        <div className='douyin-item' key={i}>
                            <p>{v.description}</p>
                            <p>{v.date}</p>
                            <img src={v.qrCode} alt=""/>
                            <button onClick={handleDeleteWeChat}>删除</button>
                            { isDeleteWeChat&&<div className='de-pop'>
                                <button onClick={()=>handleWechatSure(v,i)}>
                                确认删除
                                </button>
                                    
                                <button onClick={handleQuit}>
                                退出
                                </button>
                            </div>
                        }
                        </div>
                       
                        
                    ))
                }

            </div>

            <Candle/>
            <Footer/>
        </div>
    )
}
