import React,{useState} from 'react'
import ReactPlayer from 'react-player/youtube'
import './index.scss'

export default function  EnglishPage() {
    

    const VIDEO_LIST = [
        {
            title: '輕鬆秒學英語口語表達1000句',
            url:'https://youtu.be/k0ZVzE08fB4'
        },
        {
            title:'990個英語重要短句',
            url:'https://youtu.be/Imc_JCpv8-k'
        },
        {
            title:'1000個進階水平的英語短句',
            url:'https://youtu.be/rGHWEki4pFQ'
        },
        {
            title:'英語簡短對話&聽力特訓1000句',
            url:'https://youtu.be/OHvkElZqqZM'
        },
        {
            title:'英語會話精選200短句',
            url:'https://youtu.be/4c6tZv4vxDo'
        },
        {
            title:'磨耳朵！英語長句500句',
            url:'https://youtu.be/HkPqBEEsM0I'
        },
        {
            title:'高效練耳朵英語聽力2',
            url:'https://youtu.be/VJsLKaPxMTs'
        },
        {
            title:'秒翻英語訓練600',
            url:'https://youtu.be/0m4Nvbi3t5s'
        },
        {
            title:'母語語速 英語初級聽力練習',
            url:'https://youtu.be/-s8RF5yhK4o'
        },
        {
            title:'影子練習300句，練出一口好英語',
            url:'https://youtu.be/MOFVtP_kj_s'
        },
        {
            title:'英語句子排序練習',
            url:'https://youtu.be/tQCIdNEV38I'
        },
        {
            title:'學說一口地道英語口語',
            url:'https://youtu.be/HhPTGgRdnCk'
        },
        {
            title:'想說又不會說的常用英語長句600句',
            url:'https://youtu.be/GoWsFZLN2-E'
        },
        {
            title:'高效練耳朵英語聽力1',
            url:'https://youtu.be/MtDYXwcLidc'
        },
        {
            title:'高效練耳朵英語聽力3',
            url:'https://youtu.be/3aEqZluxmxk'
        },
        {
            title:'高效練耳朵英語聽力（初級篇',
            url:'https://youtu.be/ZsNtwhOJINE'
        },
        {
            title:'基礎英語聽力課程',
            url:'https://youtu.be/FqUimSCtxSY'
        },
        {
            title:'英語短語連讀聽力特訓',
            url:'https://youtu.be/h13N3V6mMyE'
        },
        {
            title:'英語會話輕鬆上手',
            url:'https://youtu.be/HPNHwQq_z6o'
        },
        {
            title:'英語口語發音練習-美式和英式發音',
            url:'https://youtu.be/3QwnUK7BP8o'
        },
        {
            title:'英語聽力填空練習',
            url:'https://youtu.be/6LiMq3PfBtQ'
        },
        {
            title:'最常用的地道英語口語練習',
            url:'https://youtu.be/_FIIQwck7gU'
        },
        {
            title:'基礎單詞和簡單語法就會說的英語900句',
            url:'https://youtu.be/EjqEn9lWLeA'
        },
        {
            title:'為初學者設計的簡明英語對話練習',
            url:'https://youtu.be/WKCTH4lzUbw'
        },
        {
            title:'終生受用的英文短句',
            url:'https://youtu.be/kTTIUn-QSgE'
        },
        {
            title:'你能聽懂母語語速的簡單英語短句嗎',
            url:'https://youtu.be/VeWRFW4cY0Y'
        },
        {
            title:'高效練耳朵英語聽力２（初級篇)',
            url:'https://youtu.be/rc-wrqtgRgQ'
        },
        {
            title:'高效練耳朵英語聽力（高級篇2)',
            url:'https://youtu.be/WMrDiIu-IEM'
        },
        {
            title:'學習旅遊度假時的英語短句',
            url:'https://youtu.be/4EvYGv29PTU'
        },
        {
            title:'常用英語短語500組',
            url:'https://youtu.be/2VG7rbz1q-w'
        },
        {
            title:'實用英語表達1000句',
            url:'https://youtu.be/7RwPpimUnrA'
        },
        {
            title:'常用英語口語訓練1000句',
            url:'https://youtu.be/adYyAlII_v0'
        },
       
    ]


    const [currentIndex,setCurrentIndex] = useState(0);

    const handleChangeIndex = (i)=>{
        // console.log(i)
        setCurrentIndex(i);
    }

    const autoNext = () =>{
        currentIndex === VIDEO_LIST.length ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1);
    }

    return (
        <div>
                <div className='player-wrapper'>
                <ReactPlayer
                className='react-player'
                url={VIDEO_LIST[currentIndex].url}
                width='100%'
                height='100%'
                controls
                onEnded={()=>autoNext()}
                />
            
                </div>





                <div className='video-item'>
                {
                    VIDEO_LIST&&VIDEO_LIST.map((n,i)=>(
                        <div key={i} style={i ===currentIndex ? {backgroundColor:'rgba(210, 212, 202, 0.588)',border:'1px solid white'}:{backgroundColor:'rgba(255, 155, 155, 0.688)'}} onClick={()=>handleChangeIndex(i)} >
                            <h3 
                            style={i ===currentIndex ? {backgroundColor:'rgba(250, 0, 0, 0.288)'}:{backgroundColor:'rgba(116, 201, 201, 0.688)'}}
                            >
                                {n.title}
                            </h3>
                           { currentIndex===i &&<img src={'https://res.cloudinary.com/dlapk94rx/image/upload/v1620838969/XTglobalsolution/QqDfb_fi2xmu.gif' } alt=""/>}
                        </div>
                    ))
                }

                </div>
        </div>
      
    )
}
