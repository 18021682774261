import axios from 'axios';

import getApiUrl from '../constant/apiUrl';

const url = getApiUrl();



const handleRegister = ({phone,password,email,name}) =>{

    return axios.post(`${url}/users`,{phone,password,email,name});
}


const handleLogin = ({phone,password}) =>{
    return axios.post(`${url}/users/login`,{phone,password});
}


const authService = {
    handleRegister,
    handleLogin,
}

export default authService;