const devUrl = 'http://localhost:5000';
const prodUrl = 'https://xiaopodunserver.com';

const currentEnv = process.env.REACT_APP_ENV;

const getApiUrl = () =>{
    if(currentEnv === 'dev'){
        return devUrl;
    }else{
        return prodUrl;
    }
}

export default getApiUrl;