import React,{useState} from 'react'
import './index.scss'
import QRCode from 'qrcode.react';
import {Link} from 'react-router-dom'
export default function Footer() {

    const [isShow,setIsShow] = useState(false);

    const handleShow = ()=>{
        setIsShow(true);
    }

    return (
        <div className='footer-container'>
            <Link to='/contact'>联系我们</Link>
           {/* { isShow&&
            <div className='div-f'>
                <QRCode value="https://xiaopodun.com/"/>
            </div>
           }
           {!isShow&&<button onClick={handleShow}>分享</button>} */}
            <p>联系方式: +1 6462071303  或者
            +1 2034569888
            </p>
            <p>Copyright 星天inc©2021</p>
        </div>

    )
}
