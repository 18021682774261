import React from 'react'
import Nav from '../../components/Nav/index';

import './index.scss'

export default function PostPage() {
    return (
        <div>
            <Nav/>
            <h2> I am Post Page</h2>
        </div>
    )
}
