import React,{useState,useEffect} from 'react'
import './index.scss';
import Nav from '../../components/Nav/index';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import getApiUrl from '../../constant/apiUrl';
import Footer from '../../components/Footer/index';
import Candle from '../../components/Candle/index'

export default function WechatPage({userId}) {

    const [keyword,setKeyword] = useState('');
    const [Douyins,setDouyins] = useState();

    const url = getApiUrl();

    console.log(url);
    useEffect(() => {
        const getAllDouYin = async() => {
            const result = await axios.get(`${url}/wechats`)
            console.log(result);
            if(result.data.success){
                setDouyins(result.data.data);
            }
            
        }
        getAllDouYin();
    },[url])
    const handleKeyword = (e) =>{
        setKeyword(e.target.value)
       
     
    }

    const handlePress =async (e) =>{
        if(e.key === 'Enter'){
            const result = await axios.get(`${url}/wechats/keyword`,{
                params:{keyword:keyword}
            })
            if(result.data.data.length){
                setDouyins(result.data.data)
            }
        }
    }

    return (
        <div className='wechat-container'>
            <Nav Id={userId}/>
            <div className='wechat-top'>
            <div className='home-title'>
                <h1>微信个人二维码分享</h1>
                <p>免费发布个人微信二维码,方便大家交流分享信息</p>
            </div>
            <div className='Search-btn'>
            <TextField id="outlined-basic" label="关键词搜索" variant="outlined" onChange={(e)=>handleKeyword(e)} onKeyPress={(e)=>handlePress(e)}  defaultValue={keyword} />
           
          
             
           
            </div>
            </div>
       

            <div className='douyin-posts'>
                    {
                        Douyins&&Douyins.map((v,i)=>(
                            <div key={i} className='single-douyin'>
                                {/* <h4>
                                    用户：{v.userName}
                                </h4> */}
                                <p>
                                    简介：{v.description}
                                </p>
                                <img src={v.qrCode} alt=""/>
                            </div>
                        ))
                    }
            </div>
        <Candle/>            
        <Footer/>
        </div>
    )
}
