import React,{useState,useEffect} from 'react'
import './index.scss'
import Nav1 from '../../components/Nav1/index';
import Footer from '../../components/Footer/index';
import {Button} from '@material-ui/core'

export default function NumberPage() {

    const [array,setArray] = useState([])
    const [remove,setRemove] = useState(true)
    const [show,setShow] = useState(false)
    const [model,setModel] = useState(true)

    const [sample1,setSample1] = useState([{number:1,isOn:true},{number:2,isOn:true},{number:3,isOn:true},{number:4,isOn:true}])

    const [sample2,setSample2] = useState([{number:1,isOn:true},{number:2,isOn:true},{number:3,isOn:true},{number:4,isOn:true}])

    useEffect(() => {
        const max = 100
        let temp = [] 
        for(let i = 1; i <= max; i++) {
            const item ={number:i,isOn:true}
            temp.push(item)
        }
        setArray(temp)
    },[])

    const handleClick1 = (i)=>{
        let temp = [...sample1]
        temp[i].isOn = !sample1[i].isOn
        setSample1(temp)
    }

    const handleClick = (i)=>{
        let temp = [...array];
            temp[i].isOn=!array[i].isOn
            setArray(temp)
    }
    const handleRemove = ()=>{
        setRemove()
    }

    const handleChose1 = ()=>{
        setShow(true)
        setRemove(true)
        setModel(false)
    }
    const handleRefresh = ()=>{
        window.location.reload()
    }
    const handleDelete = (i)=>{
        const temp = [...sample2];
        temp.splice(i, 1)
        setSample2(temp)

    }
    const handleDelete1 = (i)=>{
        const temp = [...array]
        temp.splice(i, 1)
        setArray(temp)
    }
    const handleKeep = ()=>{
        setShow(true)
        setRemove(false)
        setModel(false)
    }
    return (
        <div className='number-wrap'>
        <Nav1/>
                {
                    model ? <h2>选择模式</h2>
                    :
                    <div>
                        <h2>
                        全部数字
                        </h2>
                        <Button variant='contained' color='secondary' onClick={()=>handleRefresh()}>重新载入数字</Button>
                    </div>
                }
            {model&&<div className='model'>
                <div>
                <Button onClick={()=>handleChose1()} variant='contained' color='secondary' >
                    删除模式
                </Button> 
                <p>点击数字后数字删除</p>
                <div className='number-container1'>
                    {
                        sample2&&sample2.map((v,i)=>(
                            <Button className={v.isOn ? 'normal' : 'dele'} key={i} onClick={()=>handleDelete(i)}  variant='contained' color= 'default'>
                      {v.number} 
                    </Button>
                        ))
                    }
                </div>
               
                </div>

                <div>
                <Button onClick={()=>handleKeep()} variant='contained' color='secondary'>
                    保留模式
                </Button>
                <p>点击数字后数字被划掉</p>

                <div className='number-container1'>
                    {
                        sample1&&sample1.map((v,i)=>(
                            <Button className={v.isOn ? 'normal' : 'dele'} key={i} onClick={()=>handleClick1(i)}  variant='contained' color={v.isOn ? 'primary' : 'default'}>
                      {v.number} 
                    </Button>
                        ))
                    }
                </div>
                </div>
              

                
            </div>}
        {show&&<div className='number-container'>
            {!remove ?(
                array&&array.map((v,i)=>(
                    <Button className={v.isOn ? 'normal' : 'dele'} key={i} onClick={()=>handleClick(i)}  variant='contained' color={v.isOn ? 'primary' : 'default'}>
                      {v.number} 
                    </Button>
                ))
            )
            :(
                array&&array.map((v,i)=>(
                    <Button className={v.isOn ? 'normal' : 'dele'} key={i} onClick={()=>handleDelete1(i)}  variant='contained' color='default'>
                      {v.number} 
                    </Button>
                ))
            )
        }
        </div>}



        <Footer/>

        </div>
    )
}
