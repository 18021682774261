import React ,{useState}from 'react';
import ReactPlayer from 'react-player/youtube';
import './index.scss';

export default function MassagePage() {


    const VIDEO_LIST = [

        {
            title:'背部按摩，深层组织按摩',
            url:'https://www.youtube.com/watch?v=-AOLEojHyx8&ab_channel=zouyibian'
        },
        {
            title:'基础手法背部按摩',
            url:'https://www.youtube.com/watch?v=LiDYXaK1TRI&ab_channel=zouyibian'
        },
        {
            title:'背部按摩',
            url:'https://www.youtube.com/watch?v=eWhQQlSrbPg&t=23s&ab_channel=%E6%9F%AF%E5%81%89%E6%AF%85'
        },
    

    ]
    const [currentIndex,setCurrentIndex] = useState(0);

    const handleChangeIndex = (i)=>{
        // console.log(i)
        setCurrentIndex(i);
    }

    const autoNext = () =>{
        currentIndex === VIDEO_LIST.length ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1);
    }

    return (
        <div>
                <div className='player-wrapper'>
                <ReactPlayer
                className='react-player'
                url={VIDEO_LIST[currentIndex].url}
                width='100%'
                height='100%'
                controls
                onEnded={()=>autoNext()}
                />
            
                </div>



                <div className='video-item'>
                {
                    VIDEO_LIST&&VIDEO_LIST.map((n,i)=>(
                        <div key={i} style={i ===currentIndex ? {backgroundColor:'rgba(210, 212, 202, 0.588)',border:'1px solid white'}:{backgroundColor:'rgba(255, 155, 155, 0.688)'}} onClick={()=>handleChangeIndex(i)} >
                            <h3 
                            style={i ===currentIndex ? {backgroundColor:'rgba(250, 0, 0, 0.288)'}:{backgroundColor:'rgba(116, 201, 201, 0.688)'}}
                            >
                                {n.title}
                            </h3>
                           { currentIndex===i &&<img src={'https://res.cloudinary.com/dlapk94rx/image/upload/v1620838969/XTglobalsolution/QqDfb_fi2xmu.gif' } alt=""/>}
                        </div>
                    ))
                }

                </div>
        </div>
    )
}
