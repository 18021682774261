import React,{useState,useEffect} from 'react'
// import {useCookies} from 'react-cookie';
import {useHistory} from 'react-router-dom'
import axios from 'axios';
import getApiUrl from '../../constant/apiUrl';
import Select from 'react-select';
import './index.scss';
import Nav1 from '../../components/Nav1/index';
import Footer from '../../components/Footer/index';


export default function PublishPage({setNewPost}) {

    // const [cookies,setCookies] = useCookies('checkPublish')
    // const [nextPublishTime,setNextPublishTime] = useState()
    const [values,setValues] = useState({});
    const [previewLink,setPreviewLink] = useState('');
    const exampleLink = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1611874908/qr-code_gv7kjb.png';
    const [previewLink2,setPreviewLink2] = useState('');

    const [isDisabled,setIsDisabled] = useState(false);
    const [qun,setQun] = useState(true);
    const [wechat,setWechat] = useState(false);
    const [douyin,setDouyin] = useState(false);
    const [wechatDes,setWechatDes] = useState('');
    const [douyinDes,setDouyinDes] = useState('');
    const [wechatUrl,setWechatUrl] = useState('');
    const [douyinUrl,setDouyinUrl] = useState('');

    useEffect(()=>{
        if(isDisabled){
            const interval = setInterval(() => {
                setIsDisabled(false);
         }, 3000);
         return () => clearInterval(interval);
        }
       

    },[isDisabled]);

    let history = useHistory();

    const CATEGORYOPTIONS = [
        {
            value:'交友聚会',
            label:'交友聚会'
        },
        {
            value:'兴趣爱好',
            label:'兴趣爱好'
        },
        {
            value:'招聘求职',
            label:'招聘求职'
        },
        {
            value:'美食烹饪',
            label:'美食烹饪'
        },
        {
            value:'旅游出行',
            label:'旅游出行'
        },
        {
            value:'母婴育儿',
            label:'母婴育儿'
        },
        {
            value:'商品买卖',
            label:'商品买卖'
        },
        {
            value:'房屋租赁',
            label:'房屋租赁'
        },
        {
            value:'学校教育',
            label:'学校教育'
        },
        {
            value:'生活资讯',
            label:'生活资讯'
        },
        {
            value:'游戏天地',
            label:'游戏天地'
        },
        {
            value:'按摩保健',
            label:'按摩保健'
        },
        {
            value:'美容养身',
            label:'美容养身'
        },
        {
            value:'物流团购',
            label:'物流团购'
        },
        {
            value:'法律会计',
            label:'法律会计'
        },
        {
            value:'医疗',
            label:'医疗'
        },
        {
            value:'宠物',
            label:'宠物'
        },
        {
            value:'其它',
            label:'其它'
        },
    ]

    const PLACE = [
        {
            value:'纽约',
            label:'纽约',
        },
        {
            value:'洛杉矶',
            label:'洛杉矶',
        },{
            value:'波士顿',
            label:'波士顿',
        },{
            value:'费城',
            label:'费城',
        },{
            value:'芝加哥',
            label:'芝加哥',
        },{
            value:'西雅图',
            label:'西雅图',
        },{
            value:'美东',
            label:'美东',
        },{
            value:'美西',
            label:'美西',
        },{
            value:'加拿大',
            label:'加拿大',
        },{
            value:'多伦多',
            label:'多伦多',
        },{
            value:'温哥华',
            label:'温哥华',
        },{
            value:'加拿大东',
            label:'加拿大东',
        },{
            value:'加拿大西',
            label:'加拿大西',
        },{
            value:'华盛顿',
            label:'华盛顿',
        },{
            value:'旧金山',
            label:'旧金山',
        },{
            value:'休斯顿',
            label:'休斯顿',
        },{
            value:'其它',
            label:'其它',
        }
    ]


    const userId = window.localStorage.getItem('userId');
    const url = getApiUrl();
   
    // const handleCheckCookie = () =>{
    //     if(document.cookie){
    //         alert(`请于${nextPublishTime}后再次发帖`)
    //     }
    // }

    const handleInputChange = (e) => {
		const { name, value } = e.target;

		setValues({ ...values, [name]: value });
    };
    const handleInputDouyin = (e) => {
        
        setDouyinDes(e.target.value)
    }
    const handleInputWechat = (e) => {
        setWechatDes(e.target.value)
    }

    const handleUpLoadQrCode = (qrcode)=>{

        return axios.post(`${url}/files`,qrcode)
    }


    const handleFileUpload = async (e) =>{
        const file = e.target.files[0];

		const link = URL.createObjectURL(file);

        setPreviewLink(link);
        
		const formData = new FormData();
        
        formData.append('qrcode', file);
        
		const uploadResult = await handleUpLoadQrCode(formData);

		setValues({ ...values, ownerQRCode: uploadResult.data.data });
    }

    const handleFileUpload2 = async (e) =>{
        const file = e.target.files[0];
		const link = URL.createObjectURL(file);
       
		setPreviewLink2(link);
		const formData = new FormData();

		formData.append('qrcode', file);
		const uploadResult = await handleUpLoadQrCode(formData);
		setValues({ ...values, groupQRCode: uploadResult.data.data });
    }

    const handleFileUploadWeChat = async (e) =>{
        const file = e.target.files[0];
		const link = URL.createObjectURL(file);
       
		setPreviewLink2(link);
		const formData = new FormData();

		formData.append('qrcode', file);
		const uploadResult = await handleUpLoadQrCode(formData);
        setWechatUrl(uploadResult.data.data)
    }

    const handleFileUploadDouyin = async (e) =>{
        const file = e.target.files[0];
		const link = URL.createObjectURL(file);
       
		setPreviewLink2(link);
		const formData = new FormData();

		formData.append('qrcode', file);
		const uploadResult = await handleUpLoadQrCode(formData);
        setDouyinUrl(uploadResult.data.data)
    }

    const handleChangeCategory =(v) =>{
        setValues({...values, category: v.value});
    }

    const handleChangePlace = (v) =>{
        setValues({ ...values,location: v.value});
    }

    // console.log(values)

    const handlePost = ({title,description,location,category,ownerQRCode,groupQRCode,userId}) =>{

        return axios.post(`${url}/posts`,
        {title,description,location,category,ownerQRCode,groupQRCode,userId});
    }

    const handlePublishWechat = ({description,userId,qrCode}) =>{
        return axios.post(`${url}/wechats/publish`,{
            description,
            userId,
            qrCode

        })
    }

    const handlePublishDouyin = ({description,userId,qrCode}) =>{
       return axios.post(`${url}/douyins/publish`,{
            description,
            userId,
            qrCode

        })
    }

    const handleSubWe = async () =>{
        setIsDisabled(true);
        if(!wechatDes){
            alert("缺少描述内容")
        }
        if(!wechatUrl){
            alert("缺少二维码")
        }

        try {
            if(wechatDes.length>119){
                alert("内容不得超过120个字符")
            }else{
                const weChatData = await handlePublishWechat({description:wechatDes,userId:userId,qrCode:wechatUrl});
                if(weChatData.data.success){
                        history.push('/')
                }
            }
         
            
        } catch (error) {
            alert(error)

        }

    }

    const handleSubDou = async () =>{
        setIsDisabled(true);
        if(!douyinDes){
            alert("缺少描述内容")
        }
        if(!douyinUrl){
            alert("缺少二维码")
        }

        try {
            
            const douyinData = await handlePublishDouyin({description:douyinDes,userId:userId,qrCode:douyinUrl,})
            if(douyinData.data.success){
                history.push('/douyin')
            }
        } catch (error) {
            alert(error)
        }
    }

    const handleFormSubmit =async () =>{
    //     handleCheckCookie();
    //     const timeNow = new Date();
      
    //   const expiresTime =  timeNow.setTime(timeNow.getTime()+1000*60*60*4);
   
    //   setNextPublishTime(new Date(expiresTime))
    //     setCookies('checkPublish',{nextTime: new Date(expiresTime)},{expires:new Date(expiresTime)});

        setIsDisabled(true);
        if(!values.title){
            alert(" Missing title")
        }
        if(!values.description){
            alert(" Missing description")
        }
        if(!values.category){
            alert(" Missing category")
        }
        if(!values.location){
            alert(" Missing location")
        }
        if(!values.ownerQRCode){
            alert(" Missing ownerQRCode")
        }
        if(!values.groupQRCode){
            alert(" Missing groupQRCode")
        }

       
       
        try {
           
         
            const postResult = await handlePost({title:values.title,description:values.description,location:values.location,category:values.category,ownerQRCode:values.ownerQRCode,  groupQRCode:values.groupQRCode,userId})
            if(postResult.data.success){
                setNewPost(postResult.data.result)
                history.push('/group');
            }

        } catch (error) {
            alert(error)

        }

    }
    const handleQun = ()=>{
        setQun(true);
        setWechat(false);
        setDouyin(false)
    }
    const handleWechat = ()=>{
        setQun(false);
        setWechat(true);
        setDouyin(false)
    }
    const handleDouyin = ()=>{
        setQun(false);
        setWechat(false);
        setDouyin(true)
    }

    return (
        <div className="publish-bg">
          <Nav1 Id={userId} />
            <div className='publish-btn'>
                <div>
                <h3 onClick={handleQun}>发布群</h3>
              
                <hr style={qun ?{backgroundColor:'white'}: {} }/>
                </div>
                <div>
                <h3 onClick={handleWechat}>发布个人</h3>
                <hr style={wechat ?{backgroundColor:'white'}: {} }/>
                </div>
                <div>
                    <h3 onClick={handleDouyin}>发布抖音</h3>
                    <hr style={douyin ?{backgroundColor:'white'}: {} }/>
                </div>
               

            </div>

            {qun&&<div className="publish-container">
                <form onSubmit={(e)=>e.preventDefault()} >

                    <div className="content">
                        <label >标题</label>
                        <input  placeholder='请输入一个标题' type="text" name='title' value={values.title || ''}  
                        onChange={(e)=>handleInputChange(e)}/>
                    </div>
                   
                    <div className="content">
                        <label >内容</label>
                        <input className="text-more"  placeholder='请描述发帖内容' type="text" name='description' value={values.description || ''} 
                        onChange={(e)=>handleInputChange(e)}/>  
                    </div>
                    <div className='select-content'>
                        <label >分类</label>
                            <Select options={CATEGORYOPTIONS} onChange={(v)=>handleChangeCategory(v)}/>
                    </div>
                    <div className='select-content'>
                        <label >地点</label>
                        <Select options={PLACE} onChange={(v)=>handleChangePlace(v)}/>

                    </div>
                    <div className='upload-grid'>
                    <div className='upload-content'>
                        <label> 上传个人二维码</label>
                        <input type="file" placeholder='请上传个人二维码' onChange={handleFileUpload} />
                        {
                            previewLink ? (
                                <div className="upload-box">
                                <img src={previewLink} alt='preview'/>

                                </div>
                                ) :(
                                    <div className="upload-box">
                                    <img src={exampleLink} alt="example"/>

                                    </div>
                                )
                        }
                    </div>

                    <div className='upload-content'>
                        <label> 上传群二维码</label>
                        <input type="file" placeholder='请上传群二维码' onChange={handleFileUpload2} />
                        {
                            previewLink2 ? (
                                <div className="upload-box">
                                <img src={previewLink2} alt='preview'/>

                                </div>
                                ) :(
                                    <div className="upload-box">
                                    <img src={exampleLink} alt="example"/>

                                    </div>
                                )
                        }
                    </div>
                    </div>
                    

                        <div className='PostSubmit'>
                            <button style={{backgroundColor: isDisabled? 'grey' :'rgb(112, 240, 170)' }} disabled={isDisabled} onClick={()=>handleFormSubmit()}>发布</button>
                        </div>

                </form>
            </div>}

            {wechat&&<div className="publish-container">
                <form onSubmit={(e)=>e.preventDefault()} >

                    
                    <div className="content">
                        <label >微信二维码内容描述</label>
                        <input className="text-more"  placeholder='请描述发帖内容：比如您的职业等(不得超过120个字)' type="text" name='description' value={wechatDes || ''} 
                        onChange={(e)=>handleInputWechat(e)}/>  
                    </div>
                    
                    
                
                    <div className='upload-content'>
                        <label> 上传个人二维码</label>
                        <input type="file" placeholder='请上传个人二维码' onChange={handleFileUploadWeChat} />
                        {
                            wechatUrl ? (
                                <div className="upload-box">
                                <img src={wechatUrl} alt='preview'/>

                                </div>
                                ) :(
                                    <div className="upload-box">
                                    <img src={exampleLink} alt="example"/>

                                    </div>
                                )
                        }
                    </div>

                    
                  
                    

                        <div className='PostSubmit'>
                            <button style={{backgroundColor: isDisabled? 'grey' :'rgb(112, 240, 170)' }} disabled={isDisabled} onClick={()=>handleSubWe()}>发布</button>
                        </div>

                </form>
            </div>}

            {
                douyin&&<div className="publish-container">
                <form onSubmit={(e)=>e.preventDefault()} >

                    
                   
                    <div className="content">
                        <label >抖音内容描述</label>
                        <input className="text-more"  placeholder='请描述发帖内容' type="text" name='description' value={douyinDes || ''} 
                        onChange={(e)=>handleInputDouyin(e)}/>  
                    </div>
                   
                  
                    
                    <div className='upload-content'>
                        <label> 上传抖音二维码</label>
                        <input type="file" placeholder='请上传个人二维码' onChange={handleFileUploadDouyin} />
                        {
                            douyinUrl ? (
                                <div className="upload-box">
                                <img src={douyinUrl} alt='preview'/>

                                </div>
                                ) :(
                                    <div className="upload-box">
                                    <img src={exampleLink} alt="example"/>

                                    </div>
                                )
                        }
                    </div>

                    
                    

                        <div className='PostSubmit'>
                            <button style={{backgroundColor: isDisabled? 'grey' :'rgb(112, 240, 170)' }} disabled={isDisabled} onClick={()=>handleSubDou()}>发布</button>
                        </div>

                </form>
            </div>
            }
            <Footer/>
        </div>
    )
}
