import React,{useState,useEffect} from 'react'
import './index.scss'
import {Link,useHistory} from 'react-router-dom'
import axios from 'axios';
import getApiUrl from'../../constant/apiUrl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';


export default function Nav1({Id}) {

        const [userExample,setUserExample] = useState('https://res.cloudinary.com/dlapk94rx/image/upload/v1611940558/user_ov7i0s.png')
        const [userName,setUserName] = useState(Id);
        const [user,setUser] = useState({});
        const url = getApiUrl();
        const [anchorEl, setAnchorEl] = useState(null);
        let history = useHistory();
        const [anchorEl1, setAnchorEl1] = useState(null);

       
        const handleClick = (e)=>{
            setAnchorEl(e.currentTarget);

        }
        const handleClick1 = (e)=>{
            setAnchorEl1(e.currentTarget);

        }
        const handleClose = () => {
            setAnchorEl(null);
          };
          const handleClose1 = () => {
            setAnchorEl1(null);
          };

          const handleLogOut = () => {
              window.localStorage.removeItem('userId')
              window.localStorage.removeItem('token')
            //   history.push('/')
                window.location.reload()
          }

          const handleMyPage = () => {
              history.push('/user')
          }
          const handleLogin = () => {
            history.push('/login')
      }

      const handleRegister = () => {
        history.push('/register')

      }
      const handlePublish = () => {
        if(Id==='601b4a0edebc8608f510cae3'){
          history.push('/publish/admin')

        }
        else{

          history.push('/publish')
        }

      }

        useEffect(() => {

          
            if(userName) {
                setUserExample('https://res.cloudinary.com/dlapk94rx/image/upload/v1611940558/user_1_lczdfn.png')
               

            }
            else{
                setUserExample('https://res.cloudinary.com/dlapk94rx/image/upload/v1611940558/user_ov7i0s.png')
            }
        },[userName])

        useEffect( () => {
            const handleGetUser =async()=>{
                const data = await axios.get(`${url}/users/find`,
                {params:{userId:userName}},
                )
               
               

                    setUser(data.data);
                
            }
             handleGetUser();
           

        },[userName,url])

     

    return (
        <div className='nav-bar1'>
          

            <div className='logo'>
                <Link to={'/'}> <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1662499667/logo_e0zzy2.gif" alt=""/></Link>
               
            </div>
            <div className='some'>
            <div className='nav-btn'>
            
            <Link to={'/group'}>元名片</Link>

            </div>
          

            <div className='nav-btn'>
            <Link to={'/'}>微信</Link>
            </div>
           <div className='nav-btn'>
            <Link to={'/douyin'}>小红书/抖音</Link>
        
            </div>
            <div className='nav-btn'>
            <Link to={'/live'}>生活汇总</Link>

            </div>
            {/* <div className='nav-btn'>
            <Link to={'/background'}>状态动图</Link>

            </div> */}
           
            </div>
            
            <div className='avatar'>
            { Id ?<img src={userExample} alt=""/> 
                : <Button onClick={handleClick1} aria-controls="menu" aria-haspopup="true"><img src={userExample} alt=""/></Button>
                }
                <Menu
        id="menu"
        anchorEl={anchorEl1}
        keepMounted
        open={Boolean(anchorEl1)}
        onClose={handleClose1}
      >
        <MenuItem onClick={handleLogin}>登录</MenuItem>
        <MenuItem onClick={handleRegister }>注册</MenuItem>
      </Menu>               { Id&&<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                          <p id='user-name'>
                            {user.name}
                            </p>
                        </Button>}
                        <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      ><MenuItem onClick={handlePublish}>我要发帖</MenuItem>
        <MenuItem onClick={handleMyPage}>我的帖子</MenuItem>
        <MenuItem onClick={handleLogOut }>退出登录</MenuItem>
      </Menu>

            </div>
        </div>
    )
}
