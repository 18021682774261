import React,{useEffect,useState} from 'react'
import Candle from '../../components/Candle/index'
import './index.scss'
import {Link } from 'react-router-dom'
import axios from 'axios'

export default function Page1380() {

    const [autoPlay,setAutoPlay] = useState(true);
    const handlePlay  = async()=>{
        const play = document.querySelector('video')

      
        if(autoPlay){
            play.play()
        }
        else{
            play.pause()
        }
        setAutoPlay(!autoPlay);
        
    }
 
    return (
        <div className='am1380-container'>
            <h2> AM1380</h2>
            <Link to='/'>回到主页</Link>
            <div className='audio-dis'>
               <video crossOrigin autoPlay controls>
                   <source  src='http://mobilewkdm.serverroom.us:6912/;stream.mp3/playlist.m3u8' type='audio/mpeg'/>
               </video>
            </div>
            <h2>点击播放1380</h2>
            <div onClick={()=>handlePlay()} className='play-btn'>

            </div>
          
            <p>免费发布分享个人或群二维码,本网站致力于大家可以随意的选择加入或分享上传微信群。方便大家寻找志同道合，相见恨晚的人</p>
            <div className='candle'>
             
            
    
           
            <Candle/>
            </div>
        </div>
    )
}
