import React,{useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './index.scss';
import authService from '../../service/auth';

export default function RegisterPage() {

    let history =  useHistory();
    const [values,setValues] = useState({});


    const handleInputChange = (e) => {
		const { name, value } = e.target;
		const newValues = { ...values, [name]: value };

		setValues(newValues);
    };
    
    const handleButtonSubmit =async()=>{
        if(values.password !== values.password1){
            alert("请输入两次相同的密码")
        }
        else{
            const {phone, password,email,name} = values;
            try {

                const loginResult = await authService.handleRegister({
                    phone, password,email, name
                })
                console.log(loginResult)
                if(loginResult.data.success){
                    history.push('/login')
                }
                else{
                    alert(loginResult.data.message)
                }
                
            } catch (error) {
                alert("请按规则输入正确的手机号，邮箱账号和密码")
            }

        }
    }
    return (
        <div className='Register-page'>
            <div className='container-login'>
                
                <div className='login-title'>
                    <h2>注册</h2>
                </div>
                
                <div className='login-form'>
                    <form onSubmit={(e)=>e.preventDefault()}>

                        <div className='login-input'>
                            <div>
                            <label >手机号 <span className='limit'>*</span><span>好</span></label>
                            <input type="phone" name="phone" placeholder="请输入您的手机号 " value={values.phone || ''}
                            onChange={(e)=>handleInputChange(e)}
                            />
                            </div>

                            <div>
                                <label >
                                    昵称<span className='limit'>*</span><span>好好</span>
                                </label>
                                <input type="text" name="name" placeholder="请输入您的用户名" value={values.name|| ''}
                            onChange={(e)=>handleInputChange(e)}
                            />
                            </div>

                            <div>
                                <label >
                                    邮箱<span className='limit'>*</span><span>好好</span>
                                </label>
                                <input type="email" name="email" placeholder="Email" value={values.email|| ''}
                            onChange={(e)=>handleInputChange(e)}
                            />
                            </div>

                            <div>
                                <label >
                                    密码<span className='limit'>*</span><span>好好</span>
                                </label>

                                <input type="password" name="password" placeholder="密码至少六位" value={values.password || ''} 
                            onChange={(e)=>handleInputChange(e)}
                            />
                            </div>

                            <div>
                                <label >
                                    确认密码<span className='limit'>*</span>
                                </label>
 
                                <input type="password" name="password1" placeholder="密码至少六位" value={values.password1 || ''} 
                            onChange={(e)=>handleInputChange(e)}
                            />
                            </div>
                           
                            
                           
                        </div>
                        <div className='login-btn'>
                            <button onClick={()=>handleButtonSubmit()}>
                                提交
                            </button>
                        </div>
                        <div className='login-btn'>
                            <p>已经有账号?</p>
                            <Link to={'/login'}>现在登录</Link>
                        </div>

                        <div className='login-back'>
                            <Link to={'/'}>
                                回到主页
                            </Link>
                        </div>
                    </form>
                </div>


            </div>



        </div>
    )
}
