import React from 'react'
import Nav1 from '../../components/Nav1/index';
import Footer from '../../components/Footer/index';
import './index.scss';
import Candle from '../../components/Candle/index'

export default function ContactPage() {


    const userId = window.localStorage.getItem('userId');

    return (
        <div className='contact-container'>
            <Nav1 Id={userId}/>
            
            <div>
                <h2>
                    联系我们请点击
                <a href='https://www.xingtian.io/'>星天工作室</a>
                </h2>

            </div>
            <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.4434431149853!2d-73.82926528467041!3d40.75227054308803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2606cd640cb53%3A0x141db878e9815fe!2s43-06%20Main%20St%2C%20Flushing%2C%20NY%2011355!5e0!3m2!1sen!2sus!4v1612387830577!5m2!1sen!2sus" width="90%" height="400" title='12345'  ></iframe>
            </div>
            <div className='wechat'>
                <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1608753534/WechatIMG49_wpzua3.jpg" alt=""/>
                <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1608753534/WechatIMG99_tzubrm.jpg" alt=""/>
            </div>
            <Candle/>
            <Footer/>
        </div>
    )
}
