import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import './index.scss';
import authService from '../../service/auth';




export default function LoginPage({setToken,history,setUserId}) {
    
    const [values,setValues] = useState({});
    
    const handleInputChange = (e) => {
		const { name, value } = e.target;
		const newValues = { ...values, [name]: value };

		setValues(newValues);
    };
    
    const handleButtonSubmit =async() =>{

            const {phone,password} = values;
            // console.log(values)
            try {
                const loginResult = await authService.handleLogin({phone,password});
                if(loginResult.data.success){
                    const token = loginResult.data.token;
                    const userId = loginResult.data.userId;
                    setUserId(userId);
                    setToken(token);
                    window.localStorage.setItem('token', token);
                    window.localStorage.setItem('userId', userId);
                    history.history.push('/')
                }
               
            } catch (error) {
               alert("wrong phone number or password")
            }

    }


    return (
        <div className='login-page'>
            <div className='container-login'>
                
                <div className='login-title'>
                    <h2>登录</h2>
                </div>
                
                <div className='login-form'>
                    <form onSubmit={(e)=>e.preventDefault()}>

                        <div className='login-input'>
                            <input type="phone" name="phone" placeholder="手机号" value={values.phone || ''}
                            onChange={(e)=>handleInputChange(e)}
                            />
                            <input type="password" name="password" placeholder="密码" value={values.password || ''} 
                            onChange={(e)=>handleInputChange(e)}
                            />
                        </div>
                        <div className='login-btn'>
                            <button onClick={()=>handleButtonSubmit()}>
                                登录
                            </button>
                        </div>
                        <div className='login-btn'>
                            <p>还没有账号？</p>
                            <Link to={'/register'}>现在去注册</Link>
                        </div>

                        <div className='login-back'>
                            <Link to={'/'}>
                                回到主页
                            </Link>
                        </div>
                    </form>
                </div>


            </div>



        </div>
    )
}
