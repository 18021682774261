import React,{useState,useEffect} from 'react'
import Nav1 from '../../components/Nav1/index';
import Footer from '../../components/Footer/index';
import {Link} from 'react-router-dom'
import './index.scss'

export default function LivePage() {

    const data = [
        {
            name:'人生重开模拟器',
            url:'https://liferestart.syaro.io/view/index.html',
        },
       
        {
            name:'美国找工网',
            url:'https://168worker.com/'
        },
      
        {
            name:'华人资讯网',
            url:'https://www.nychinaren.com/',
        },
        {
            name:'世界语言学习',
            url:'https://www.zerotohero.ca/',
        },
        {
            name:'世界之声',
            url:'https://aporee.org/maps/',
        },
        {
            name:'云旅游',
            url:'https://www.airpano.com/',
        },
     
        {
            name:'小工具合集',
            url:'https://tools.miku.ac/',
        },
        {
            name:'二维码生成器',
            url:'/tool'
        },
       
       
        
    ]
    const [isIframe,setIsIframe] = useState(false)
    const [height,setHeight] = useState(0)
    const [item,setItem] = useState()

    useEffect(() => {
        const temp = window.innerHeight
        setHeight(temp-82)
    },[])
    const handleClick = (v) => {
        setIsIframe(true)
        setItem(v)
    }
    const handleBack = ()=>{
        setIsIframe(false)
        setItem('')
    }
    return (
        <div className='live-wrap'>
            <Nav1/>
           { isIframe&&<div className='top'>
                <button onClick={()=>handleBack()}>
                    返回生活汇总
                </button>
            </div>}
                {!isIframe &&<div className='live-container'>

                    {/* <div className='link-item'>
                        <Link to='/game' >
                            人生重开模拟器
                        </Link>
                    </div> */}
                    <section>
                    {
                        data&&data.map((v,i)=>(
                            <div key={i} className='live-container'>
                                {i!==data.length-1 ?
                                <button onClick={()=>handleClick(v)}>
                                    {v.name}
                                </button>
                                : <Link to={v.url}><button>{v.name}</button></Link>    
                            }

                            </div>
                        ))
                    }
                    </section>
                   

                    

                </div>}

                {
                    isIframe&&<div className='iframe-wrap'>
                        <iframe src={item.url} width='100%' height={`${height}px`} frameBorder="0"></iframe>
                    </div>
                }

            <Footer/>
        </div>
    )
}
