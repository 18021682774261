import React,{useState,useEffect} from 'react'
import Nav1 from '../../components/Nav1/index';

export default function GamePage() {

    const [height,setHeight] = useState(0)

    useEffect(() => {
        const temp = window.innerHeight
        setHeight(temp-42)
    },[])
    return (
        <div>
            <Nav1/>

            <div>
                <iframe src="http://liferestart.syaro.io/view/index.html" width="100%" height={`${height}px`} frameborder="0"></iframe>
            </div>
        </div>
    )
}
