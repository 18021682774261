import React,{useState,useEffect} from 'react'
import './index.scss';
import Nav from '../../components/Nav/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import getApiUrl from '../../constant/apiUrl';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer/index';
import Select from 'react-select';
import Candle from '../../components/Candle/index'


export default function Cards({userId}) {


    const PLACE = [
        {
            value:'曼哈顿',
            label:'曼哈顿',
            isChecked: false,
        },
        {
            value:'布鲁克林',
            label:'布鲁克林',
            isChecked: false,
        },
        {
            value:'法拉盛',
            label:'法拉盛',
            isChecked: false,
        },
        {
            value:'Elmhurst',
            label:'Elmhurst',
            isChecked: false,
        },
        {
            value:'Staten Island',
            label:'Staten Island',
            isChecked: false,
        },
        {
            value:'Long Island City',
            label:'Long Island City',
            isChecked: false,
        },
        {
            value:'Long Island',
            label:'Long Island',
            isChecked: false,
        },
       {
            value:'其它地区',
            label:'其它地区',
            isChecked: false,

        }
    ]
    const CATE = [
        {
            value:'餐饮',
            label:'餐饮',
            isChecked: false,

        },
        {
            value:'地产经纪',
            label:'地产经纪',
            isChecked: false,

        },
        {
            value:'律师',
            label:'律师',
            isChecked: false,

        },
        {
            value:'汽车买卖',
            label:'汽车买卖',
            isChecked: false,

        },
        {
            value:'保险',
            label:'保险',
            isChecked: false,

        },
        {
            value:'贷款',
            label:'贷款',
            isChecked: false,

        },
        {
            value:'会计',
            label:'会计',
            isChecked: false,

        },
        {
            value:'搬家',
            label:'搬家',
            isChecked: false,

        },
        {
            value:'旅行',
            label:'旅行',
            isChecked: false,

        },
        {
            value:'驾校',
            label:'驾校',
            isChecked: false,

        },
        {
            value:'装修',
            label:'装修',
            isChecked: false,

        },
        {
            value:'医生',
            label:'医生',
            isChecked: false,

        },
        {
            value:'美容',
            label:'美容',
            isChecked: false,

        },
        {
            value:'按摩',
            label:'按摩',
            isChecked: false,

        },
        {
            value:'美甲',
            label:'美甲',
            isChecked: false,

        },
        {
            value:'移民',
            label:'移民',
            isChecked: false,

        },
        {
            value:'印刷',
            label:'印刷',
            isChecked: false,

        },
        {
            value:'婚庆',
            label:'婚庆',
            isChecked: false,
        },
        {
            value:'摄影',
            label:'摄影',
            isChecked: false,
        },
        {
            value:'其它',
            label:'其它',
            isChecked: false,
        },
    ]

    const [locations,setLocations] = useState(PLACE)
    const [location,setLocation] = useState('')
    const [categorys,setCategorys] = useState(CATE)
    const [category,setCategory] = useState()
    const [keyword,setKeyword] = useState('');

    const [Posts,setPosts] = useState([]);
    const [isPopup,setIsPopup] = useState(false);
    const [currentPost,setCurrentPost] = useState({});
    const [currentIndex,setCurrentIndex] = useState();
    const [startX,setStartX] = useState();
  

    const url = getApiUrl();

    const handleCheckLocation = (v,i) =>{
        let temp = [...PLACE]
       for(let j =0;j<temp.length;j++){
            if(i ===j){
                if(!v.isChecked){

                    temp[j].isChecked = true;
                    setLocation(temp[i].value);

                }
                else{
                    temp[j].isChecked = false;
                    setLocation('')
                }
               
            }
            else{
                temp[j].isChecked = false;

            }
       }
       console.log(temp)

        setLocations(temp);
        setKeyword('')


    }
    useEffect(()=>{
        const getPosts = async() =>{
            const result= await axios.get(`${url}/cards/all`)
            console.log(result);
            setPosts(result.data.data)
        }
        getPosts();
       
},[url])

    const handleCheckCategory = (v,i) =>{
        let temp = [...CATE]
       for(let j =0;j<temp.length;j++){
            if(i ===j){
                if(!v.isChecked){

                    temp[j].isChecked = true;
                    setCategory(temp[i].value);

                }
                else{
                    temp[j].isChecked = false;
                    setCategory('')
                }
               
            }
            else{
                temp[j].isChecked = false;

            }
       }
       console.log(temp)
        setCategorys(temp);
        setKeyword('')

        
    }
    // console.log(locations,location)
    // console.log(categorys,category);


  

    useEffect(()=>{
        const handleSearch = async()=>{
            if(category&&location){
                const result = await axios.get(`${url}/cards/search`,{
                    params:{category:category,location:location}
                })
                setPosts(result.data.data)
                setKeyword('')

            }
            else if(category){
                const result = await axios.get(`${url}/cards/category`,{
                    params:{category:category}
                })
                setPosts(result.data.data)
                setKeyword('')


            }
            else if(location){
                const result = await axios.get(`${url}/cards/location`,{
                    params:{location:location}
                })
                setPosts(result.data.data)
                setKeyword('')


            }
            else{
    
            }
        }
        handleSearch();

    },[category,location,url])
    console.log(Posts);

    const handleKeyword = (e) =>{
        setKeyword(e.target.value)
        if(e.target.value){
            setCategorys(CATE)
            setLocations(PLACE)
            setCategory('')
            setLocation('')
        }
     
    }

    const handlePress =async (e) =>{
        if(e.key === 'Enter'){
            const result = await axios.get(`${url}/cards/keyword`,{
                params:{keyword:keyword}
            })
            setPosts(result.data.data)
        }
    }


    // const handleSearchKeyword = async() =>{
    //     if(keyword){
    //         const result = await axios.get(`${url}/posts/keyword`,{
    //             params:{keyword:keyword}
    //         })
    //         setPosts(result.data.data)
    //     }
    // }
   

    const handlePopup = async(v,i) =>{
        setIsPopup(true)
        setCurrentPost(Posts[i])
        setCurrentIndex(i);
       await axios.post(`${url}/cards/view`,{
            id:v._id
        })
        

    }
    const handleClose = () =>{
        setIsPopup(false)
    }

    const handleCategory =async(v)=>{
        console.log(v)

        if(v.value){
            const result = await axios.get(`${url}/cards/category`,{
                params:{category:v.value}
            })
            console.log(result.data.data)

            setPosts(result.data.data)
        }
    }

    const handleLocation =async(v)=>{
        console.log(v)
        if(v.value){
            const result = await axios.get(`${url}/cards/location`,{
                params:{location:v.value}
            })
            console.log(result.data.data)
            setPosts(result.data.data)
        }
    }

    const handleStart = (e)=>{
        setStartX(e.changedTouches[0].pageX)
    }

    const handleEnd = (e)=>{
        const endX = e.changedTouches[0].pageX;
        
        if(currentIndex ===0){
            if(startX-endX>10){

                alert('已经是第一篇帖子了')
            }
            if(endX - startX >10){
                setCurrentPost(Posts[currentIndex+1])
                setCurrentIndex(currentIndex+1)
            }
        }
        else if(currentIndex+1 <Posts.length){
            if(startX-endX>10){

                setCurrentPost(Posts[currentIndex-1])
                setCurrentIndex(currentIndex-1)


            }
            if(endX - startX >10){
                setCurrentPost(Posts[currentIndex+1])
                setCurrentIndex(currentIndex+1)

            }
        }
        else if(currentIndex+1 === Posts.length){
            if(startX-endX>10){
                setCurrentPost(Posts[currentIndex-1])
                setCurrentIndex(currentIndex-1)

                
            }
            if(endX - startX >10){
                alert('已经是最后一篇帖子了')
            }
        }
        else{

        }

    }

   

   

    return (
        <div className='home-container'>

            <Nav Id={userId}/>
            <div className='home-title'>
                <h1>海外华人微信分享平台</h1>
                <p>免费发布分享个人或群二维码,本网站致力于大家可以随意的选择加入或分享上传自己的微信或者名片。方便大家寻找志同道合，相见恨晚的人</p>
            </div>
            <div className='grid-2'>
                <div className='flex'>
                    <h2>地区</h2>
                <div className='locations'>
                {
                    locations&&locations.map((v,i)=>(
                        <div key={i} >
                        <FormControlLabel control={
                            <Checkbox checked={v.isChecked} onChange={()=>handleCheckLocation(v,i)} />
                        } label={v.label} />
                        </div>
                    
                    ))
                }
                </div>
                </div>


                <div className='flex'>
                <h2>分类</h2>

                <div className='category'>
                {
                categorys&&categorys.map((v,i)=>(
                    <div key={i}>
                    <FormControlLabel  control={
                        <Checkbox checked={v.isChecked} onChange={()=>handleCheckCategory(v,i)} color='primary'/>
                    } label={v.label} />
                    </div>
                   
                ))
                }
            </div>
                </div>
               

                
            </div>
           
            <div className='phone-select'>
                <div className='phone-item'>
                    <h2>地区</h2>
                    <Select id='erzi' options={PLACE}
                    onChange={(v)=>handleLocation(v)}
                    />
                </div>
                <div className='phone-item'>
                    <h2>分类</h2>
                    <Select id='erzi' options={CATE}
                    onChange={(v)=>handleCategory(v)}
                    />
                </div>
            </div>
           
           
           <div>
               
           </div>
            <div className='Search-btn'>
            <TextField id="outlined-basic" label="关键词搜索" variant="outlined" onChange={(e)=>handleKeyword(e)} onKeyPress={(e)=>handlePress(e)}  defaultValue={keyword} />
           
          
            
           
            </div>

           

            <div  className='data-container'>
                 
                   { Posts.length>0&&Posts.map((v,i)=>(
                       <div className='data-shadow'  key={i}>
                        <Card onClick={()=>handlePopup(v,i)}>
                            <CardActionArea>
                         
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {v.title.length<6 ? v.title : `${v.title.substr(0,5)}...` }
                            </Typography>
                            <Typography variant="body1" color="textSecondary" component="p">
                                {v.description.length<10 ? v.description : `${v.description.substr(0,9)}...` }
                            </Typography>
                            <div className='time'>
                                <div className='view'>
                                    <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1612220808/visibility_s2tmmk.png" alt="view"/>
                                    <p>{v.views}</p>
                                </div>
                                <div className='view'>
                                <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1612220808/calendar_1_zeqdyx.png" alt="view"/>
                                    <p>{v.date}</p>
                                </div>
                            </div>
                            </CardContent>
                            </CardActionArea>
                          

                        </Card>
                        </div>
                    ))}
              
                   
                
            </div>


          {  isPopup&&<div className='popup' onTouchStart={(e)=>handleStart(e)} onTouchEnd={(e)=>handleEnd(e)}  >

                <div className='popup-title'>
                    <h2>
                        {currentPost.title} 
                    </h2>
                    <p>扫码观看元名片</p>
                </div>
                <div className='popup-des'>
                    <textarea readOnly  defaultValue={currentPost.description}></textarea>
                </div>

                <div className='popup-info'>
                    <p>地点:{currentPost.location}</p>
                    <p>分类:{currentPost.category}</p>
                </div>
                <div className='popup-img'>
                    <div>
                        <h2>缩略图</h2>
                    <img src={currentPost.frontImage} alt=""/>

                    </div>
                    <div>
                        <h2>元名片二维码</h2>

                    <img src={currentPost.qrCode} alt=""/>

                    </div>
                </div>
                <button onClick={handleClose}>X</button>

            </div>}
                            <Candle/>
                <Footer/>
        </div>
    )
}
